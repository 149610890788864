import componentRegistry from '../registry.js';
import { mobileMenuEntryTemplateURL } from '../../configuration/Configuration.js';
import * as pageScroll from '../../helpers/view/pagescroll.js';
import renderNodeMenu from './side-nav-panel-mobile.liquid';
import './side-nav-panel-mobile.less';

// this is the file responsible of the mobile sideNav

/** @type {string} */
const CLASSNAMES = {
  ACTIVE: 'isActive',
  OPEN: 'menu-open',
};

/** @type {boolean} */
let ISACTIVE = false;

export default class SideNavPanelMobile {
  constructor({ el }) {
    this.el = el;
    document.querySelector('.js-menu-toggle').addEventListener('click', this.toggleMenu);
    this.el.addEventListener('click', this.goToLink);
    this.el.addEventListener('click', this.closeNode);
    this.el.addEventListener('click', this.getNode.bind(this));
  }

  toggleMenu() {
    document.body.classList.toggle(CLASSNAMES.OPEN);
    // Avoid bug that allows you to continue scrolling the page at the end of the menu scroll
    document.body.classList.contains(CLASSNAMES.OPEN) ? pageScroll.lock() : pageScroll.unlock();
  }

  /** @param {Event} e */
  async getNode(e) {
    const target = e.target.closest('.js-menu-item');
    if (!target || ISACTIVE === true) {
      return;
    }
    e.preventDefault();

    // add active class to block multi request
    ISACTIVE = true;
    const { id, node } = target.dataset;

    try {
      const data = await (await fetch(mobileMenuEntryTemplateURL({ pathId: id, nodeType: node }))).json();
      const currentMenu = this.el.querySelector('.js-menu-content');
      currentMenu.insertAdjacentHTML('beforeend', renderNodeMenu(data));
      currentMenu.scrollIntoView();

      // menu create by the Liquid file
      const lastMenu = this.el.querySelector('.js-menu-secondary:last-child');
      lastMenu.classList.add(CLASSNAMES.ACTIVE);
    } catch {
      throw new TypeError('Menu template has not been retrieved.');
    } finally {
      ISACTIVE = false;
    }
  }

  /** @param {Event} e */
  closeNode(e) {
    const target = e.target.closest('.js-menu-back');
    if (!target) {
      return;
    }
    e.preventDefault();
    const subMenu = target.closest('.js-menu-secondary');
    subMenu?.classList.remove(CLASSNAMES.ACTIVE);
    subMenu?.addEventListener(
      'transitionend',
      () => {
        subMenu.remove();
      },
      {
        once: true,
      }
    );
  }

  /** @param {Event} e */
  goToLink(e) {
    const target = e.target.closest('.js-menu-link');
    if (!target) {
      return;
    }
    e.preventDefault();
    window.location = target.getAttribute('data-url');
  }
}

componentRegistry.define('js-menu', SideNavPanelMobile);
