import $ from 'jquery';
import '../configuration/global-jquery.js';
import Overlay from '../components/overlay/index.js';
import '../components/header/index-mobile.js';
import '../components/side-nav-panel-mobile/index.js';
import '../components/cart-items-counter/index.js';
import '../components/iframe-resizer/index.js';
import '../components/header-search/index-mobile.js';
import '../components/expander/index.js';
import '../components/stratecarousel/index.js';
import '../components/search-sub-offer/index.js';

$('label a').on('tap click', function (e) {
  e.stopPropagation();
});

// Create hyperlink to element width data-hyperlink attribute
$(document).on('click', '[data-hyperlink]', function (e) {
  var $target = $(e.target);
  // /!\ target != currentTarget
  // Ignore fake anchor ("hyperlink") if:
  // - the event has been prevented (Note: sometimes default can be prevented only after this listener was executed)
  // - the target of the click is or is inside one of the following matching element
  // - the target of the click is inside an other hyperlink / sub hyperlink (an other one than the one we handle here)
  if (
    e.defaultPrevented ||
    $target.closest('button,input,label,a,[data-hyperlink-prevent]').length ||
    $target.closest('[data-hyperlink]').not(e.currentTarget).length
  ) {
    return;
  }

  window.location = $(e.currentTarget).data('hyperlink');
});

// ACCORDION
$('#content').on('click', '.js-accordion-title', function (event) {
  // #TODO: refactor this function
  var self = $(this);

  // if Link
  if (event.target.closest('a, .store-btn') || self.find('.chevron').length !== 0) {
    return;
  }

  event.preventDefault();

  if (self.hasClass('preOpen')) {
    self.parent().toggleClass('close');
    self.toggleClass('close');
    self.next().toggleClass('close');
    if (self.next().find('.seeMore').length && self.next().find('.seeMore').hasClass('open')) {
      self.next().find('.seeMore').trigger('click');
    }
    self.next().css('max-height', '');
    return false;
  }

  if (self.next()[0] && self.next()[0].classList.contains('js-accordion-content')) {
    if (self.hasClass('open')) {
      self.next()[0].style.maxHeight = '0';
    } else {
      let that = self.next()[0];
      let newHeight = 0;
      $(that)
        .children()
        .get()
        .forEach((ele) => (newHeight += $(ele).outerHeight(true)));
      $(that)[0].style.maxHeight = newHeight + 'px';
      if (self.parents('.js-accordion-content').length > 0) {
        that = self.parents('.js-accordion-content');
        const parentHeight = that.outerHeight(true);
        newHeight += parentHeight;
        $(that)[0].style.maxHeight = newHeight + 'px';
      }
    }
    self.parent().toggleClass('open');
    self.toggleClass('open');
    self.next().toggleClass('open');
    return false;
  }
  if (self.hasClass('open')) {
    // self.next()[0].style.maxHeight = "0";
  } else {
    self.find('.label').css('font-weight', 'bold');

    $('.js-accordion').each(function () {
      if (!self.hasClass('FA-stimuli')) {
        if (self.hasClass('open') && $(this).children().length > 0) {
          self.toggleClass('open');
          self.children().toggleClass('open');
          self.children('.js-accordion-content')[0].style.maxHeight = '0';
        }
      }
    });
  }

  self.parent().toggleClass('open');
  self.toggleClass('open');
  self.next().toggleClass('open');

  // scroll to open accordion
  setTimeout(function () {
    if (self.parent().hasClass('js-pasteOnTop')) {
      var scrollTo = self.offset();
      var scrollTop;

      if ($('.js-searchbar').css('display') === 'none') {
        scrollTop = scrollTo.top - 51;
      } else {
        scrollTop = scrollTo.top - 109;
      }

      window.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    }
  }, 150);
});

$('.strateSeo').on('click', '.js-accordion', function () {
  var $toggle = $(this);
  var $container = $toggle.closest('.strateSeo-container');
  var $link = $toggle.children('.toogle');

  if ($container.hasClass('open')) {
    $link.html($link.attr('data-open'));
  } else {
    $link.html($link.attr('data-close'));
  }

  $container.toggleClass('open');
});

//
$('.js-seeMore').on('click', function () {
  var $btn = $(this);
  var $container = $btn.parent('.js-seeMoreContainer');
  var moreLabel = $btn.data('label-more');
  var isMore = $btn.text().trim() === moreLabel;
  var newLabel;

  if (isMore) {
    newLabel = $btn.data('label-less');
  } else {
    newLabel = moreLabel;

    // specific to subList opened
    // they need to be closed before closing seeMore
    var $subItems = $container.children('.js-subItem.open');

    var paddingTop = parseFloat($('#content').css('paddingTop'));
    var windowHeight = $(window).height() - paddingTop;

    if (windowHeight < $container.height()) {
      window.scrollTo({
        top: $container.offset().top - paddingTop,
        behavior: 'smooth',
      });
    }

    if ($subItems.length) {
      closeSeeMoreSubItems($subItems);
    }
  }

  $container.toggleClass('open', isMore);
  $btn.text(newLabel);
});

// specific to subList opened
/** @param {jQuery} $items */
function closeSeeMoreSubItems($items) {
  $items.removeClass('open').css({ maxHeight: '' });
}

$('.back').on('click', function (event) {
  event.preventDefault();
  window.history.back();
});

//
if ($('#swipe .imgArbo').length === 3) {
  $('#swipe .imgArbo').addClass('full');
}

// Open/close category list and category list with subscategory
var openItemClass = 'open';

$('.js-subMain').on('click', function () {
  var element = $(this);
  var container = element.parent();
  var containerNewHeight = '';

  if (!container.hasClass(openItemClass)) {
    var subList = container.children('.js-subList');
    containerNewHeight = container.height() + subList.height();
  }

  container.css({ maxHeight: containerNewHeight }).toggleClass(openItemClass);
});

//
$('#spacePostListSorter span').on('click', function () {
  var self = $(this);
  var type = self.data('filtertype');
  var loader = new Overlay();

  $.ajax({
    url: self.data('url'),
    type: 'GET',
    beforeSend: function () {
      loader.show();
    },
    complete: function () {
      loader.hide();
    },
    success: function (codeHtml) {
      $('#spacePostListContainer').html(codeHtml);
      $('[data-filtertype]').parents().removeClass('selected');
      $("[data-filtertype='" + type + "']")
        .parents()
        .addClass('selected');
    },
  });
});
