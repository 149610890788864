import componentRegistry from '../registry.js';

/**
 * @example
 * <section id="productList" class="mobileProductList js-stratecarousel" data-component-id="test"">
 * ....
 * Checks if the current element contains an empty simplecarousel and hide it if so
 */

export default class Carousel {
  constructor({ el }) {
    this.el = el;
    this.hideIfStrateIsEmpty(this.isSimpleCarrouselEmpty());
  }

  isSimpleCarrouselEmpty() {
    var element = this.el.getElementsByClassName('simpleCarousel');
    return element == null ? false : element[0].childElementCount == 0;
  }

  hideIfStrateIsEmpty(isEmpty) {
    if (isEmpty) {
      this.el.classList.add('hide');
    }
  }
}

componentRegistry.define('js-stratecarousel', Carousel);
