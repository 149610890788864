import componentRegistry from '../registry.js';
import './header.less';

const HEADER_CLASS = 'header';
const CLASSNAMES = {
  SCROLLED_HEADER_CLASS: `scrolled-${HEADER_CLASS}`,
  UNSCROLLED_HEADER_CLASS: `unscrolled-${HEADER_CLASS}`,
  SCROLLED_SUBHEADER_CLASS: `scrolled-sub${HEADER_CLASS}`,
};

export default class HeaderMobile {
  constructor({ el }) {
    const header = (this.el = el);
    let position = window.scrollY;

    /** @param {boolean} forceHide */
    function handleHeaderVisibility(forceHide = false) {
      requestAnimationFrame(() => {
        const newPosition = window.scrollY;
        header.classList.toggle(
          CLASSNAMES.SCROLLED_HEADER_CLASS,
          forceHide || (newPosition > 52 && newPosition > position)
        );
        header.classList.toggle(CLASSNAMES.UNSCROLLED_HEADER_CLASS, forceHide || newPosition < position);
        header.classList.toggle(CLASSNAMES.SCROLLED_SUBHEADER_CLASS, forceHide || newPosition > 20);
        position = newPosition;
      });
    }

    window.addEventListener('scroll', () => handleHeaderVisibility());
  }
}

componentRegistry.define('js-header', HeaderMobile);
