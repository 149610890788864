import renderSearchAutoCompleteItem from './autocomplete-item.liquid';
import DebounceEventListener from '../../helpers/event/debounce-listener.js';
import componentRegistry from '../registry.js';
import addDelegateEventListener from '../../helpers/event/add-delegate-event-listener.js';
import isFromValidationKey from '../../helpers/event/is-from-validation-key.js';
import deleteHistorySuggestion from '../../scripts/api/autocomplete/deleteHistorySuggestion.js';
import addHistorySuggestion from '../../scripts/api/autocomplete/addHistorySuggestion.js';
import getSuggestions from '../../scripts/api/autocomplete/getSuggestions.js';
import './header-search-mobile.less';

const ENTRIES_LIMIT = 7;
const MIN_HISTORY_ENTRIES = 2;
const MIN_CHARS_TO_SHOW_HISTORY = 3;
const TRACKING_HISTORY_KEY = 'hist';
const CLASSNAMES = {
  HEADER: 'js-searchbar',
  AUTOCOMPLETE_ITEM: 'js-autocomplete-item',
  AUTOCOMPLETE_ITEM_TITLE: 'js-autocomplete-item-title',
  AUTOCOMPLETE_HISTORY_REMOVE: 'js-autocomplete-history-remove',
};

export default class AutoComplete {
  #previousValue = '';
  #form;
  #searchInput;
  #searchEmpty;
  #searchOpen;
  #searchClose;
  #list;

  constructor({ el }) {
    this.#form = el.querySelector('#QuickSearchForm');
    this.#searchInput = el.querySelector(`.${CLASSNAMES.HEADER}-input`);
    this.#searchEmpty = el.querySelector(`.${CLASSNAMES.HEADER}-empty`);
    this.#searchOpen = document.querySelector(`.${CLASSNAMES.HEADER}-open`);
    this.#searchClose = el.querySelector(`.${CLASSNAMES.HEADER}-close`);
    this.#list = el.querySelector('.js-autocomplete-list');
    this.#previousValue = this.#searchInput.value;

    this.#searchInput.addEventListener('input', new DebounceEventListener(() => this.#loadAutocompleteList(), 250));
    this.#searchInput.addEventListener('focus', this.#onInputFocus.bind(this));

    this.#searchEmpty.addEventListener('click', this.#clearSearchBar.bind(this));
    this.#searchInput.addEventListener('keyup', this.#toggleSearchEmpty.bind(this));
    this.#searchInput.addEventListener('click', this.#openSearchBar.bind(this));
    this.#searchInput.addEventListener('change', this.#onInputChange.bind(this));
    if (this.#searchOpen) {
      this.#searchOpen.addEventListener('click', this.#openSearchBar.bind(this));
    }
    this.#searchClose.addEventListener('click', this.#closeSearchBar.bind(this));
    this.#form.addEventListener('submit', this.#submitForm.bind(this));
    // remove history events
    addDelegateEventListener(
      this.#list,
      'keydown',
      `.${CLASSNAMES.AUTOCOMPLETE_HISTORY_REMOVE}`,
      (event) => isFromValidationKey(event) && this.#onRemoveHistory(event)
    );
    addDelegateEventListener(this.#list, 'mousedown', `.${CLASSNAMES.AUTOCOMPLETE_HISTORY_REMOVE}`, (event) =>
      this.#onRemoveHistory(event)
    );
    // validate suggestion
    addDelegateEventListener(
      this.#list,
      'keydown',
      `.${CLASSNAMES.AUTOCOMPLETE_ITEM_TITLE}`,
      (event) => isFromValidationKey(event) && this.#onItemClick(event)
    );
    addDelegateEventListener(this.#list, 'mousedown', `.${CLASSNAMES.AUTOCOMPLETE_ITEM_TITLE}`, (event) =>
      this.#onItemClick(event)
    );

    this.#toggleSearchEmpty();
  }

  set #searchTerm(value) {
    this.#searchInput.value = value;
  }

  get #searchTerm() {
    return this.#searchInput.value;
  }

  //
  // bind events after render
  //

  // #bindEventsAfterRender() {
  //   this.#list
  //     .querySelectorAll('.js-autocomplete-item-title')
  //     .forEach((item) => item.addEventListener('keydown', this.#onItemClick.bind(this)));
  //   this.#list
  //     .querySelectorAll('.js-autocomplete-item-title')
  //     .forEach((item) => item.addEventListener('mousedown', this.#onItemClick.bind(this)));

  //   this.#list
  //     .querySelectorAll('.js-autocomplete-history-remove')
  //     .forEach((item) => item.addEventListener('keydown', this.#onRemoveHistory.bind(this)));
  //   this.#list
  //     .querySelectorAll('.js-autocomplete-history-remove')
  //     .forEach((item) => item.addEventListener('mousedown', this.#onRemoveHistory.bind(this)));
  // }

  //
  // Functions
  //

  #openSearchBar() {
    // display sticky search bar and deactivate body scroll
    document.body.classList.add('searchbar-open', 'disable-global-scroll');
    // display autocomplete panel list
    this.#list.classList.add('active');

    this.#searchInput.focus();
  }

  #closeSearchBar() {
    document.body.classList.remove('searchbar-open', 'disable-global-scroll');
    this.#list.classList.remove('active');
  }

  #toggleSearchEmpty() {
    this.#searchEmpty.classList.toggle('hide', !this.#searchTerm.length);
  }

  #clearSearchBar(e) {
    e.stopPropagation();
    this.#searchEmpty.classList.add('hide');

    this.#searchTerm = '';
    this.#searchInput.focus();

    this.#onInputChange();
    this.#openSearchBar();
    this.#renderInitialHistory();
  }

  #autoCompleteEntries = null;
  async #loadAutocompleteList() {
    if (this.#searchTerm === this.#previousValue) {
      return;
    }

    const term = (this.#previousValue = this.#searchTerm);

    const promise = (this.#autoCompleteEntries = getSuggestions(term, {
      historyCount: term.length >= MIN_CHARS_TO_SHOW_HISTORY ? MIN_HISTORY_ENTRIES : 0,
      count: ENTRIES_LIMIT,
    }));

    const entries = await promise;
    // loadAutocompleteList could be called while the promise is waiting, in that case, discard the result of the former
    if (promise !== this.#autoCompleteEntries) {
      return;
    }

    this.#displayData(entries);
  }

  #onInputChange() {
    if (!this.#searchTerm) {
      this.#list?.replaceChildren();
    }
  }

  #onInputFocus() {
    if (!this.#searchTerm) {
      this.#renderInitialHistory();
    }
  }

  #displayData(data) {
    this.#list.replaceChildren();
    if (data?.length) {
      const content = data.map((singleData, i) => renderSearchAutoCompleteItem({ ...singleData, index: i })).join('');
      this.#list.insertAdjacentHTML('beforeend', content);
    }
    //this.#bindEventsAfterRender();
  }

  #onItemClick(event) {
    const target = event.target;
    const currentTarget = target.closest(`.${CLASSNAMES.AUTOCOMPLETE_ITEM}`); // for event delegation
    if (!currentTarget) {
      return;
    }

    const { value, type } = currentTarget.dataset;

    // store the previous value to avoid a new request if the values are equal
    this.#previousValue = value;

    // replace input value by clicked text
    this.#searchTerm = value;

    // change value of hidden field for tracking
    this.#form.querySelector('.js-searchTracking').value = type === 'history' ? TRACKING_HISTORY_KEY : 1;

    addHistorySuggestion(value);

    // submit form to go to search page
    this.#form.submit();
  }

  #onRemoveHistory(event) {
    const currentTarget = event.target.closest(`.${CLASSNAMES.AUTOCOMPLETE_ITEM}`);
    const { value } = currentTarget.dataset;
    event.preventDefault();
    deleteHistorySuggestion(value);
    currentTarget.remove();
    // if (!this.#list.children.length) {
    //   this.#list.classList.remove('active');
    // }
  }

  #submitForm() {
    const value = (this.#searchTerm = this.#searchTerm.trim());
    if (value) {
      addHistorySuggestion(value);
    }
  }

  async #renderInitialHistory() {
    const data = await getSuggestions('', { historyCount: ENTRIES_LIMIT, count: ENTRIES_LIMIT });
    this.#displayData(data);
  }
}

componentRegistry.define('js-searchbar', AutoComplete);
