import componentRegistry from '../registry.js';

export default class StackedCards {
  constructor({ el }) {
    this.stack = el;
    this.title = this.stack.querySelector('.js-stack-cards-title');
    this.stackContainer = this.stack.querySelector('.js-stack-cards-container');
    this.cards = [...this.stackContainer.children].reverse();
    if (this.cards.length === 0) {
      // TODO: should not render if no data in backend
      el.remove();
      return;
    }
    this.bullets = this.stack.querySelectorAll('.js-stack-bullet');

    this.SCALE = this.cards.length <= 20 ? 0.05 : 1 / this.cards.length;
    this.OFFSET = 10;
    this.MINTOUCHDISTANCE = 20;
    this.SWIPELABEL = 'swipe';
    this.SCROLLLABEL = 'scroll';

    this.xTouchStart = null;
    this.yTouchStart = null;
    this.touchDirectionLock = null;

    this.selectedCard = null;

    this.stackContainer.addEventListener('touchstart', this.handleTouchStart, false);
    this.stackContainer.addEventListener('touchend', this.handleTouchEnd, false);
    this.stackContainer.addEventListener('touchmove', this.handleTouchMove, false);
    // this.stack.addEventListener('click', this.onTap, false);
    this.stackContainer.addEventListener('animationend', this.handleAnimationEnd, false);

    this.setCardsStyle();
    this.setMargins();
    this.setAnimationStyle();
  }

  /**
   * ----- Event handlers for mobile touch -----
   *
   * @param {TouchEvent} event
   */
  handleTouchStart = (event) => {
    const touch = event.changedTouches ? event.changedTouches[0] : event;
    this.xTouchStart = touch.clientX;
    this.yTouchStart = touch.clientY;
    this.touchDirectionLock = null;
  };

  handleTouchMove = (event) => {
    if (this.xTouchStart) {
      const touch = event.changedTouches ? event.changedTouches[0] : event;
      const xDiff = touch.clientX - this.xTouchStart;
      const yDiff = touch.clientY - this.yTouchStart;
      const distance = Math.sqrt(Math.pow(xDiff, 2) + Math.pow(yDiff, 2));

      if (this.touchDirectionLock == null && distance >= this.MINTOUCHDISTANCE) {
        this.touchDirectionLock = Math.abs(xDiff) < Math.abs(yDiff) ? this.SCROLLLABEL : this.SWIPELABEL;
        this.xTouchStart = touch.clientX;
        this.yTouchStart = touch.clientY;
      }

      if (this.touchDirectionLock === this.SWIPELABEL) {
        event.preventDefault();
      }
    }
  };

  handleTouchEnd = (event) => {
    if (this.xTouchStart && this.touchDirectionLock === this.SWIPELABEL) {
      const touch = event.changedTouches ? event.changedTouches[0] : event;
      const xDist = touch.clientX - this.xTouchStart;
      this.onSwipe(event, xDist > 0 ? 'right' : 'left');
      this.xTouchStart = null;
    }
  };

  handleAnimationEnd = (event) => {
    if (event.animationName.includes('swipe')) {
      this.onSwipeAnimationEnd(event.animationName);
    } else if (event.animationName === 'tap-to-rotate') {
      this.onTapAnimationEnd();
    }
  };

  onSwipeAnimationEnd = (animationName) => {
    this.selectedCard.classList.remove('stack-cards-animation-swipe', 'stack-cards-animation-' + animationName);
    this.stackContainer.append(this.selectedCard);
    this.cards.unshift(this.cards.pop());
    this.setCardsStyle();
    this.setActiveBullet(parseInt(this.selectedCard.dataset.index));
    this.selectedCard = null;
  };

  onTapAnimationEnd = () => {
    this.selectedCard.classList.remove('stack-cards-animation-tap-to-rotate');
    window.location.href = this.selectedCard.querySelector('.js-stack-cards-link').href;
    this.selectedCard = null;
  };

  /**
   * ----- User interactions Swipe and Tap -----
   *
   * @param {TouchEvent} event
   * @param {'right' | 'left'} direction
   */
  onSwipe(event, direction) {
    const card = event.target.closest('.stack-cards__elem');
    if (!this.isCurrentCard(card)) {
      return;
    }
    this.selectedCard = card;
    this.selectedCard.classList.add('stack-cards-animation-swipe');
    this.selectedCard.classList.add('stack-cards-animation-swipe-' + direction);
  }

  /*onTap = event => {
    event.preventDefault();
    const card = event.target.closest('.stack-cards__elem');
    if (!this.isCurrentCard(card)) {
      return;
    }
    this.selectedCard = card;
    this.selectedCard.classList.add('stack-cards-animation-tap-to-rotate');
  }*/

  isCurrentCard(currentCard) {
    const card = this.stackContainer.querySelector('.stack-cards__elem:first-child');
    return card === currentCard;
  }

  setActiveBullet(currentIndex) {
    const activeIndex = currentIndex + 1 === this.cards.length ? 0 : currentIndex + 1;
    this.bullets.forEach((bullet, index) => {
      if (activeIndex === index) {
        bullet.classList.add('active');
      } else {
        bullet.classList.remove('active');
      }
    });
  }

  /**
   * Style functions - getCssTransform - getCssAnimation - setStackCardStyle
   *
   * @param {number} index
   */
  getScale(index) {
    return 1 - this.SCALE * index;
  }

  getOffset(index) {
    return -this.OFFSET * index;
  }

  getCardCssTransform(index) {
    const reversedIndex = this.cards.length - (index + 1);
    const offset = this.getOffset(reversedIndex);
    const scale = this.getScale(reversedIndex);
    return `translate(0, ${offset}px) scale(${scale})`;
  }

  setMargins() {
    const lastIndex = this.cards.length - 1;
    const lastOffset = this.getOffset(lastIndex);
    const lastScale = this.getScale(lastIndex);
    const lastCard = this.cards[lastIndex];
    const margin = Math.abs(lastOffset + (lastCard.clientHeight - lastCard.clientHeight * lastScale) / 2);
    this.stack.style.marginTop = `${margin}px`;
    this.title.style.marginBottom = `${margin}px`;
  }

  setCardsStyle() {
    this.cards.forEach((elem, index) => {
      elem.style.transform = this.getCardCssTransform(index);
      elem.style.zIndex = index + 1;
    });
  }

  setAnimationStyle() {
    const css = `
      @keyframes swipe-right {
        50% {
          transform: translate(50%, 10px) scale(0.9);
          z-index: -1;
        }
        100% {
          transform: ${this.getCardCssTransform(0)};
          z-index: -1;
        }
      }

      @keyframes swipe-left {
        50% {
          transform: translate(-50%, 10px) scale(0.9);
          z-index: -1;
        }
        100% {
          transform: ${this.getCardCssTransform(0)};
          z-index: -1;
        }
      }
    `;
    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.innerText = css;
    document.head.appendChild(style);
  }
}

componentRegistry.define('js-stack-cards', StackedCards);
