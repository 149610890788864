import componentRegistry from '../registry.js';

export default class StickyGoodDeal {
  constructor({ el }) {
    this.sticky = el;
    this.button = this.sticky.querySelector('.js-sticky-promo-button');
    this.content = this.sticky.querySelector('.js-sticky-promo-content');
    this.header = this.sticky.querySelector('.js-sticky-promo-header');

    this.ySwipeStart = null;

    this.header.addEventListener('touchstart', this.handleTouchStart, true);
    this.header.addEventListener('touchend', this.handleTouchEnd, true);

    this.fixFooter();
  }

  fixFooter = () => {
    document.querySelector('.js-footer').classList.add('padded');
  };

  toggleSticky = () => {
    this.sticky.classList.toggle('visible');
  };

  showSticky() {
    this.sticky.classList.add('visible');
  }

  hideSticky() {
    this.sticky.classList.remove('visible');
  }

  handleTouchStart = (event) => {
    const touch = event.changedTouches ? event.changedTouches[0] : event;
    this.ySwipeStart = touch.clientY;
  };

  handleTouchEnd = (event) => {
    if (!this.ySwipeStart) {
      return;
    }
    const touch = event.changedTouches ? event.changedTouches[0] : event;
    const xDiff = touch.clientY - this.ySwipeStart;
    if (Math.abs(xDiff) <= 20) {
      // click event
      if (event.target === this.button) {
        this.toggleSticky();
      } else if (!this.sticky.classList.contains('visible')) {
        this.showSticky();
      }
    } else if (xDiff > 0) {
      this.hideSticky();
    } else {
      this.showSticky();
    }
  };
}

componentRegistry.define('js-sticky-good-deal', StickyGoodDeal);
